
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { GlobalGetters } from "@/store/modules/global/types";
/**
 * SLOT is required!
 */

@Component
export default class FormInput extends Vue {
  @Prop() value!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop() placeholder!: string;

  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;

  get labelid(): string {
    return this.$slots.default
      ? this.$slots.default![0].text!.replace(" ", "-").toLowerCase()
      : this.value;
  }
}
